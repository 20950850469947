@import url("https://fonts.googleapis.com/css2?family=Anton&family=Azeret+Mono:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: Inter;
  overscroll-behavior: none;
  font-size: 21px;
  line-height: calc(16px * 1.5);
}

body {
  background-color: #f9f1eb;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

img {
  width: 100%;
  display: block;
}

h1 {
  font-size: 89px;
  line-height: calc(89px * 1.3);
  font-weight: 700;
  font-family: Anton;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h2 {
  font-size: 55px;
  line-height: calc(55px * 1.3);
  font-weight: 700;
  font-family: Anton;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h3 {
  font-size: 34px;
  line-height: calc(34px * 1.3);
  font-weight: 700;
  font-family: Anton;
  text-transform: uppercase;
  letter-spacing: 1px;
}

code {
  font-size: 16px;
}

code,
.mono {
  font-family: "Azeret Mono", monospace;
}

input {
  font-family: "Azeret Mono", monospace;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #ddd;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  * {
    font-size: 16px;
    line-height: calc(16px * 1.2);
  }

  h1 {
    font-size: 55px;
    line-height: calc(55px * 1.3);
  }

  h2 {
    font-size: 34px;
    line-height: calc(34px * 1.3);
  }

  h3 {
    font-size: 21px;
    line-height: calc(21px * 1.3);
  }

  code, input {
    font-size: 12px;
  }
}
